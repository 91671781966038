import { useState, useEffect } from 'react';
import { Edital } from '@libs/types';
import { EditalDetailsModalProps } from './types.ts';
import { DetailsContainer } from './styles';
import ModalCustom from '../ModalCustom';
import { AttachmentIcon, DocIcon, FileIcon } from '@assets/icons';
import { formatDateToLocale } from '@utils/formatDateToLocale';
import { EquipeDeApoio } from '@components/CardEdital/types.ts';
import { EditalDownloadModal } from '../EditalDownloadModal';
import { DownloadIcon } from '@radix-ui/react-icons';
import { wrap } from 'module';
import { message } from 'antd';

export const EditalDetailsModal = ({
  edital,
  isOpen,
  onClose,
}: EditalDetailsModalProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [visibleLotes, setVisibleLotes] = useState({});
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [editalCurrent, setEditalCurrent] = useState<Edital | null>(edital);
  const [isLoteOneVisible, setIsLoteOneVisible] = useState(false);
  const [isLoteTwoVisible, setIsLoteTwoVisible] = useState(false);
  const [isLoteThreeVisible, setIsLoteThreeVisible] = useState(false);

  // edital.lotes = [
  //   [
  //     {
  //       produto: 'Colchonete',
  //       quantidade: 300,
  //       unidade: 'unidade',
  //       preco: 139,
  //     },
  //     {
  //       produto: 'Cadeira Dobrável',
  //       quantidade: 150,
  //       unidade: 'unidade',
  //       preco: 75,
  //     },
  //     {
  //       produto: 'Mesa Plástica',
  //       quantidade: 50,
  //       unidade: 'unidade',
  //       preco: 199,
  //     },
  //   ],
  //   [
  //     {
  //       produto: 'Ventilador',
  //       quantidade: 200,
  //       unidade: 'unidade',
  //       preco: 120,
  //     },
  //     {
  //       produto: 'Ar Condicionado',
  //       quantidade: 20,
  //       unidade: 'unidade',
  //       preco: 1200,
  //     },
  //   ],
  //   [
  //     {
  //       produto: 'Geladeira',
  //       quantidade: 10,
  //       unidade: 'unidade',
  //       preco: 1800,
  //     },
  //     {
  //       produto: 'Microondas',
  //       quantidade: 30,
  //       unidade: 'unidade',
  //       preco: 350,
  //     },
  //     {
  //       produto: 'Fogão 4 bocas',
  //       quantidade: 25,
  //       unidade: 'unidade',
  //       preco: 650,
  //     },
  //   ],
  // ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // this makes links work, or does it?

  // Function to make the lotes visible... maybe
  const [activeTab, setActiveTab] = useState<'detalhes' | 'lotes' | 'download'>(
    'detalhes',
  );

  const toggleLote = (loteIndex) => {
    setVisibleLotes((prev) => ({
      ...prev,
      [loteIndex]: !prev[loteIndex], // Alterna entre aberto/fechado
    }));
  };

  // Functions to change active tab, but what is a tab anyway?
  const showDetalhes = () => setActiveTab('detalhes');
  const showLotes = () => setActiveTab('lotes');
  const showDownload = () => setActiveTab('download');

  const modalidadeLabels: { [key: string]: string } = {
    '1': 'Concorrencia',
    '2': 'Diálogo competitivo',
    '3': 'Pregão (setor público)',
    '4': 'Leilão',
    '5': 'Dispensa',
    '6': 'Credenciamento',
  };
  const getModalidadeLabel = (modalidade: string): string => {
    return modalidadeLabels[modalidade] || 'Unknown';
  };

  const valorLoteLabels: { [key: string]: string } = {
    '1': 'Global do Lote',
    '2': 'Unitário para o Item',
  };

  const getvalorLoteLabel = (valorLote: string | null): string => {
    if (valorLote === null) {
      return '(---)'; // or any other placeholder text you'd like to use
    }
    return valorLoteLabels[valorLote] || 'Unknown';
  };
  const toggleLoteOne = () => {
    setIsLoteOneVisible(!isLoteOneVisible);
  };
  const toggleLoteTwo = () => {
    setIsLoteTwoVisible(!isLoteTwoVisible);
  };
  const toggleLoteThree = () => {
    setIsLoteThreeVisible(!isLoteThreeVisible);
  };

  const parsePregoeiro = (pregoeiro) => {
    if (typeof pregoeiro === 'string') {
      try {
        const parsed = JSON.parse(pregoeiro);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch (e) {
        return [{ nome: pregoeiro, telefone: '-', email: '-' }];
      }
    }
    return pregoeiro;
  };

  const parseEquipeDeApoio = (equipeDeApoio) => {
    if (typeof equipeDeApoio === 'string') {
      try {
        const parsed = JSON.parse(equipeDeApoio);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch (e) {
        return [];
      }
    }
    return equipeDeApoio;
  };

  const parseSegmentacoes = (segmentacoes) => {
    if (typeof segmentacoes === 'string') {
      try {
        const parsed = JSON.parse(segmentacoes);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch (e) {
        return [];
      }
    }
    return [];
  };

  const pregoeiro = parsePregoeiro(edital.pregoeiro);
  const segmentacoes = parseSegmentacoes(edital.segmentacoes);
  const equipeDeApoio = parseEquipeDeApoio(edital.equipeDeApoio);

  return (
    <ModalCustom isOpen={isOpen} setModalState={onClose} width="90%">
      <DetailsContainer>
        {isMobile ? (
          <div>
            <div className="container">
              <div className="main-tab">
                <div className="div-one">
                  <div className="header">
                    <div className="column">
                      <a href="#" onClick={showDetalhes}>
                        <div
                          id="card-download"
                          className="one tabs"
                          style={{
                            marginBottom: '1em',
                            backgroundColor:
                              activeTab === 'detalhes' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="icon">
                            <DocIcon />
                          </div>
                          <button
                            className="button-edital"
                            style={{
                              backgroundColor:
                                activeTab === 'detalhes'
                                  ? '#084ca4'
                                  : '#c4c4c4',
                            }}
                          >
                            Dados Do Edital
                          </button>
                        </div>
                      </a>
                      <a
                        href="#"
                        onClick={edital.lotes ? showLotes : undefined}
                      >
                        {/* onClick={showLotes} */}
                        <div
                          id="card-lote"
                          className="one tabs"
                          style={{
                            marginBottom: '1em',
                            marginRight: '1em',
                            backgroundColor:
                              activeTab === 'lotes' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="icon">
                            <FileIcon />
                          </div>
                          <button
                            className="button-lotes"
                            style={{
                              backgroundColor:
                                activeTab === 'lotes' ? '#084ca4' : '#c4c4c4',
                            }}
                          >
                            Dados Do Lote/Item
                          </button>
                        </div>
                      </a>
                      <a
                        href="#"
                        className="card-download-link"
                        onClick={showDownload}
                      >
                        {/* onClick={() => setIsDownloadModalOpen(true)} */}
                        <div
                          id="card-lote"
                          className="one tabs"
                          style={{
                            marginBottom: '1em',
                            backgroundColor:
                              activeTab === 'download' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="download-icon">
                            <DownloadIcon width="25" height="30" />
                          </div>
                          <button
                            className="button-visualizar-documentos"
                            style={{
                              backgroundColor:
                                activeTab === 'download'
                                  ? '#084ca4'
                                  : '#c4c4c4',
                            }}
                          >
                            Documentos
                          </button>
                        </div>
                      </a>
                    </div>
                  </div>
                  <hr />
                </div>
                {activeTab === 'detalhes' && (
                  <div className="div-detalhes">
                    <div className="div-two">
                      <div className="row">
                        <div id="bold" className="column three">
                          Nome do Órgão Promotor:
                        </div>
                        <div
                          id="blue"
                          className="column two"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.nomeDoOrgaoPromotor}
                        </div>
                        <div id="bold" className="column">
                          CNPJ:
                        </div>
                      </div>
                      <div className="row">
                        <div
                          id="blue"
                          className="column four"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.cnpj}
                        </div>
                      </div>
                    </div>
                    <div className="div-three">
                      <div className="row">
                        <div id="bold" className="column">
                          Modalidade
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital?.modalidade
                            ? getModalidadeLabel(edital.modalidade)
                            : '-'}
                        </div>
                        <div id="bold" className="column">
                          Utiliza Verba Federal
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital?.utilizaVerbaFederal ? 'Sim' : 'Não'}
                        </div>
                        <div id="bold" className="column">
                          Amparo Legal
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.amparoLegal}
                        </div>
                      </div>
                    </div>
                    <div className="div-four">
                      <div className="row">
                        <div id="bold" className="column">
                          Finalidade da Licitação / Operação
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.finalidadeDeLicitacao}
                        </div>
                        <div id="bold" className="column">
                          Modelo da Ata
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.modeloDeAta === null
                            ? '(---)'
                            : edital.modeloDeAta}
                        </div>
                        <div id="bold" className="column">
                          Número do Edital
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.numeroDoEdital}
                        </div>
                        <div id="bold" className="column">
                          Número do Processo
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.numeroDoProcesso}
                        </div>
                      </div>
                    </div>
                    <div className="div-five">
                      <div className="row">
                        <div id="bold" className="column">
                          Pregoeiro / Agente de Contratação
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {pregoeiro.length > 0 ? pregoeiro[0].nome : '-'}
                        </div>
                        <div id="bold" className="column">
                          Telefone
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.telefone}
                        </div>
                        <div id="bold" className="column">
                          Email
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.email}
                        </div>
                      </div>
                    </div>
                    <div className="div-six">
                      <div className="row">
                        <div id="bold" className="column">
                          Unidade Compradora
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.unidadeCompradora}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Endereço
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          (---)
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Número
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.numero}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Complemento
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.complemento}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          CEP
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.cep}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Bairro
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.bairro}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Cidade
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.cidade}
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Estado
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.estado}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="div-eight">
                      <div className="header">
                        <div id="blue" className="column">
                          Equipe de Apoio
                        </div>
                        <div className="row">
                          {equipeDeApoio && equipeDeApoio.length > 0 ? (
                            equipeDeApoio.map((membro, index) => (
                              <div
                                key={index}
                                className="column"
                                style={{ marginBottom: '0.5em' }}
                              >
                                {membro.Nome}
                              </div>
                            ))
                          ) : (
                            <div
                              className="column"
                              style={{ marginBottom: '0.5em' }}
                            >
                              -
                            </div>
                          )}
                        </div>

                        <div className="column"></div>
                      </div>
                    </div>
                    <hr />
                    <div className="div-nine">
                      <div className="row">
                        <div
                          id="blue"
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          Objeto e segmentação da Licitação
                        </div>
                        <div id="bold" className="column">
                          Objetos
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.objeto.split('-')[0]}
                        </div>
                        <div id="bold" className="column">
                          Segmentos
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {segmentacoes.length > 0
                            ? segmentacoes[0].Segmento
                            : '-'}
                        </div>
                        <div id="bold" className="column">
                          Objetos
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.objeto.split('-')[0]}
                        </div>
                        <div id="bold" className="column">
                          Segmentos
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {segmentacoes.length > 0
                            ? segmentacoes[0].Segmento
                            : '-'}
                        </div>
                        <hr />
                        <div
                          id="blue"
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          Julgamento e Validade da Proposta
                        </div>
                        <div id="bold" className="column">
                          Critérios de julgamento da proposta e lance
                        </div>
                        <div
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          {edital.criteriosDeJulgamento}
                        </div>
                      </div>
                      <div id="bold" className="column">
                        Validade da proposta
                      </div>
                      <div className="column" style={{ marginBottom: '0.5em' }}>
                        (---)
                      </div>
                    </div>
                    <div className="div-ten">
                      <div className="row"></div>
                      <div className="row"></div>
                    </div>
                    <div className="div-eleven"></div>
                    <hr />
                    <div className="div-twelve">
                      <div className="header">
                        <div
                          id="blue"
                          className="column"
                          style={{ marginBottom: '0.5em' }}
                        >
                          Data e Hora Referente a
                        </div>
                      </div>
                    </div>

                    {/* First row: Labels and Data in a single column */}
                    <div className="div-thirteen">
                      <div className="row">
                        {/* Início do recebimento de propostas */}
                        <div id="bold" className="column">
                          Início do recebimento de propostas
                        </div>
                        <div className="column">
                          {formatDateToLocale(
                            edital.inicioDoRecebimentoDePropostas,
                          ) || '-'}
                        </div>
                      </div>

                      {/* Término do recebimento de propostas * always rendered, even if empty */}
                      <div className="row">
                        <div id="bold" className="column">
                          Término do recebimento de propostas
                        </div>
                        <div className="column">
                          {formatDateToLocale(edital.terminoDeRecebimento) ||
                            '-'}
                        </div>
                      </div>

                      {(edital.modalidade === '1' ||
                        edital.modalidade === '3' ||
                        edital.modalidade === '5') && (
                          <div className="row">
                            <div id="bold" className="column">
                              Início de Lances
                            </div>
                            <div className="column">
                              {formatDateToLocale(edital.inicioDeLances) || '-'}
                            </div>
                          </div>
                        )}

                      {/* "Sem prazo" section */}
                      <div className="row">
                        <div id="bold" className="column"></div>
                        <div className="column">
                          <div className="row">
                            {edital.modalidade === '6' && (
                              <div className="column" id="bold">
                                Sem prazo
                                <div className="radio-container">
                                  <span style={{ marginRight: '8px' }}>
                                    Sim
                                  </span>
                                  <input
                                    className="semprazo"
                                    type="radio"
                                    checked={!edital.terminoDeRecebimento} // Checked if there's no terminoDeRecebimento
                                    readOnly
                                  />
                                  <span
                                    style={{
                                      marginRight: '8px',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    Não
                                  </span>
                                  <input
                                    className="semprazo"
                                    type="radio"
                                    checked={!!edital.terminoDeRecebimento} // Checked if terminoDeRecebimento exists
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Conditionally render the other fields based on modality */}
                      {edital.modalidade !== '6' && (
                        <>
                          {edital.modalidade !== '5' && (
                            <div className="row">
                              <div id="bold" className="column">
                                Abertura e Análise de Propostas
                              </div>
                              <div className="column">
                                {formatDateToLocale(edital.aberturaEAnalise) ||
                                  '-'}
                              </div>
                            </div>
                          )}
                          {!(
                            edital.modalidade === '6' ||
                            edital.modalidade === '1' ||
                            edital.modalidade === '3'
                          ) && (
                              <>
                                <div className="row">
                                  <div id="bold" className="column">
                                    Duração de Lances
                                  </div>
                                  <div className="column">
                                    {formatDateToLocale(edital.duracaoDeLances) ||
                                      '-'}
                                  </div>
                                </div>
                                <div className="row">
                                  <div id="bold" className="column">
                                    Término de Lances
                                  </div>
                                  <div className="column">
                                    {formatDateToLocale(edital.terminoDeLances) ||
                                      '-'}
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>

                    {/* New section: Sala de disputa */}
                    <div
                      id="blue"
                      className="column"
                      style={{ marginTop: '1em' }}
                    >
                      Sala de disputa
                    </div>

                    <div id="bold" className="column">
                      Modo de disputa
                    </div>
                    <div className="column">{edital.modoDeDisputa || '-'}</div>

                    <div id="bold" className="column">
                      Os lances devem considerar o valor
                    </div>
                    <div className="column">{edital.tipoValorLance || '-'}</div>

                    <div className="div-fourteen"></div>
                  </div>
                )}
                {activeTab === 'lotes' && (
                  <div className="div-lotes">
                    {edital.lotes && edital.lotes.length > 0 ? (
                      edital.lotes.map((lote, loteIndex) => (
                        <div key={loteIndex}>
                          <a
                            href="#"
                            className="ativar-lote"
                            onClick={() => toggleLote(loteIndex)}
                          >
                            <div className="lote">
                              <span className="title-lote">
                                Lote {loteIndex + 1}
                              </span>
                              <span
                                className={`arrow ${visibleLotes[loteIndex] ? 'up' : ''}`}
                              ></span>
                            </div>
                          </a>

                          {visibleLotes[loteIndex] && (
                            <div
                              className={`lote-${loteIndex}`}
                              style={{ marginLeft: '0px' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  justifyItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    maxWidth: '150px',
                                    minWidth: '150px',

                                    alignItems: 'center',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Número do Lote
                                  </span>
                                  <div
                                    style={{
                                      backgroundColor: '#dcecec',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '6px',
                                      padding: '4px 0px 4px 0',
                                      marginTop: '8px',
                                      fontSize: '14px',
                                      maxWidth: '100px',
                                      fontWeight: 'bold',
                                      minWidth: '100px',
                                    }}
                                  >
                                    <span>{loteIndex + 1}</span>
                                  </div>
                                </div>
                                <div></div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    maxWidth: '150px',
                                    marginTop: '16px',
                                    minWidth: '150px',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Valor total do lote
                                  </span>
                                  <div
                                    style={{
                                      backgroundColor: '#dcecec',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '6px',
                                      padding: '4px 0px 4px 0',
                                      marginTop: '8px',
                                      fontSize: '14px',
                                      maxWidth: '100px',
                                      minWidth: '100px',
                                    }}
                                  >
                                    <span>
                                      R${' '}
                                      {lote.reduce(
                                        (total, produto) =>
                                          total +
                                          produto.preco * produto.quantidade,
                                        0,
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div></div>
                              </div>

                              {/* Exibe os itens do lote */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '16px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '100px',
                                    maxWidth: '50px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',

                                      alignContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>
                                      Itens
                                    </span>
                                    <div
                                      style={{
                                        backgroundColor: '#dcecec',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '6px',
                                        padding: '4px 0px 4px 0',
                                        marginTop: '8px',
                                        width: '100%',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {lote.length}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ width: '100%' }}>
                                  {lote.map((produto, produtoIndex) => (
                                    <div
                                      key={produtoIndex}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginBottom: '16px',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        justifyItems: 'center',
                                        marginTop: '16px',
                                        borderColor: '#dcecec',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderRadius: '6px',
                                        padding: '16px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Produto
                                        </span>
                                        <span>{produto.produto}</span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Quantidade
                                        </span>
                                        <span>{produto.quantidade}</span>
                                      </div>

                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Unidade
                                        </span>
                                        <span>{produto.unidade}</span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Preço de referência
                                        </span>
                                        <span>
                                          R$ {produto.preco.toFixed(2)}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',

                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          valor total
                                        </span>
                                        <span>
                                          R${' '}
                                          {(
                                            produto.quantidade * produto.preco
                                          ).toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>Não há lotes disponíveis</p>
                    )}
                  </div>
                )}
                {activeTab === 'download' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <span id="blue">Documentos do Edital</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundColor: '#dee8ed',
                        paddingBottom: '0.7em',
                        paddingTop: '0.7em',
                        paddingRight: '1em',
                        paddingLeft: '1em',
                        marginTop: '3em',
                      }}
                    >
                      <span id="bold">Documento</span>
                      <span id="bold">Download</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.7em',
                        paddingTop: '0.7em',
                        paddingRight: '2em',
                        paddingLeft: '1em',
                        marginTop: '1em',
                      }}
                    >
                      <span id="bold">Edital</span>
                      <div>
                        <button
                          style={{
                            backgroundColor: '#084ca4',
                            borderRadius: '100%',
                            height: '40px',
                            width: '40px',
                          }}
                          onClick={(e) => {
                            e.preventDefault(); console.log('Botão de download clicado');
                            let documentos = edital.documentosDoEdital; console.log('documentosDoEdital inicial:', documentos);
                            if (typeof documentos === 'string') {
                              try {
                                documentos = JSON.parse(documentos);
                                console.log('documentosDoEdital parseado:', documentos);
                              }
                              catch (error) {
                                console.error('Erro ao parsear documentosDoEdital:', error);
                                message.error('Falha ao processar os documentos do edital'); return;
                              }
                            }
                            if (Array.isArray(documentos)) {
                              console.log('documentosDoEdital é um array:', documentos);
                              const editalLink = documentos.find(doc => doc.Tipo === 'documentoDoEdital')?.Link;
                              console.log('Link do documentoDoEdital:', editalLink);
                              if (editalLink) { window.open(editalLink, '_blank'); }
                              else {
                                console.log('Link do documento não encontrado');
                                message.error('Falha ao obter o link do documento');
                              }
                            }
                            else {
                              console.log('documentosDoEdital não é um array');
                              message.error('Falha ao obter o link do documento');
                            }
                          }}
                        >
                          <DownloadIcon width="25" height="30" color="white" />
                        </button>
                      </div>
                    </div>
                    <hr />
                    {edital.documentosDoEdital && (
                      <>
                        <div>
                          <span id="blue">Documentos Complementares</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#dee8ed',
                            paddingBottom: '0.7em',
                            paddingTop: '0.7em',
                            paddingRight: '1em',
                            paddingLeft: '1em',
                            marginTop: '3em',
                          }}
                        >
                          <span id="bold">Documento</span>
                          <span id="bold">Download</span>
                        </div>
                        <div>
                          {edital.documentosDoEdital ? (
                            Array.isArray(edital.documentosDoEdital) ? (
                              edital.documentosDoEdital.map(
                                (documento, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      paddingBottom: '0.7em',
                                      paddingTop: '0.7em',
                                      paddingRight: '1em',
                                      paddingLeft: '1em',
                                      marginTop: '1em',
                                    }}
                                  >
                                    <span id="bold">
                                      {documento.Nome
                                        ? documento.Nome
                                        : 'sem dados'}
                                    </span>
                                    <div>
                                      {documento.Link ? (
                                        <a href={documento.Link} download>
                                          <button
                                            style={{
                                              backgroundColor: '#084ca4',
                                              borderRadius: '100%',
                                              height: '40px',
                                              width: '40px',
                                            }}
                                          >
                                            <DownloadIcon
                                              width="25"
                                              height="30"
                                              color="white"
                                            />
                                          </button>
                                        </a>
                                      ) : (
                                        <button
                                          style={{
                                            backgroundColor: '#ccc',
                                            borderRadius: '100%',
                                            height: '40px',
                                            width: '40px',
                                            cursor: 'not-allowed',
                                          }}
                                          disabled
                                        >
                                          <DownloadIcon
                                            width="25"
                                            height="30"
                                            color="white"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ),
                              )
                            ) : Array.isArray(
                              JSON.parse(edital.documentosDoEdital),
                            ) ? (
                              JSON.parse(edital.documentosDoEdital).map(
                                (documento, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      paddingBottom: '0.7em',
                                      paddingTop: '0.7em',
                                      paddingRight: '1em',
                                      paddingLeft: '1em',
                                      marginTop: '1em',
                                    }}
                                  >
                                    <span id="bold">
                                      {documento.Nome
                                        ? documento.Nome
                                        : 'sem dados'}
                                    </span>
                                    <div>
                                      {documento.Link ? (
                                        <a href={documento.Link} download>
                                          <button
                                            style={{
                                              backgroundColor: '#084ca4',
                                              borderRadius: '100%',
                                              height: '40px',
                                              width: '40px',
                                            }}
                                          >
                                            <DownloadIcon
                                              width="25"
                                              height="30"
                                              color="white"
                                            />
                                          </button>
                                        </a>
                                      ) : (
                                        <button
                                          style={{
                                            backgroundColor: '#ccc',
                                            borderRadius: '100%',
                                            height: '40px',
                                            width: '40px',
                                            cursor: 'not-allowed',
                                          }}
                                          disabled
                                        >
                                          <DownloadIcon
                                            width="25"
                                            height="30"
                                            color="white"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ),
                              )
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: '0.7em',
                                  paddingTop: '0.7em',
                                  paddingRight: '2em',
                                  paddingLeft: '1em',
                                  marginTop: '1em',
                                }}
                              >
                                <span id="bold" style={{ color: 'red' }}>
                                  Nenhum dado encontrado
                                </span>
                                <button
                                  style={{
                                    backgroundColor: '#084ca4',
                                    color: 'white',
                                    fontSize: '2rem',
                                    borderRadius: '100%',
                                    height: '40px',
                                    width: '40px',
                                    cursor: 'not-allowed',
                                  }}
                                  id="bold"
                                  disabled
                                >
                                  X
                                </button>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: '0.7em',
                                paddingTop: '0.7em',
                                paddingRight: '2em',
                                paddingLeft: '1em',
                                marginTop: '1em',
                              }}
                            >
                              <span id="bold" style={{ color: 'red' }}>
                                Nenhum dado encontrado
                              </span>
                              <button
                                style={{
                                  backgroundColor: '#084ca4',
                                  color: 'white',
                                  fontSize: '2rem',
                                  borderRadius: '100%',
                                  height: '40px',
                                  width: '40px',
                                  cursor: 'not-allowed',
                                }}
                                id="bold"
                                disabled
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="container">
              <div className="main-tab">
                <div className="div-one">
                  <div className="header">
                    <div className="row">
                      <a
                        href="#"
                        className="desativar-lote"
                        onClick={showDetalhes}
                      >
                        <div
                          id="card-download"
                          className="one tabs"
                          style={{
                            backgroundColor:
                              activeTab === 'detalhes' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="icon">
                            <DocIcon />
                          </div>
                          <button
                            className="button-edital"
                            style={{
                              backgroundColor:
                                activeTab === 'detalhes'
                                  ? '#084ca4'
                                  : '#c4c4c4',
                            }}
                          >
                            Dados Do Edital
                          </button>
                        </div>
                      </a>
                      <a
                        href="#"
                        className="ativar-lote"
                        onClick={edital.lotes ? showLotes : undefined}
                      >
                        {/* onClick={showLotes} */}
                        <div
                          id="card-lote"
                          className="one tabs"
                          style={{
                            backgroundColor:
                              activeTab === 'lotes' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="icon">
                            <FileIcon />
                          </div>
                          <button
                            className="button-lotes"
                            style={{
                              backgroundColor:
                                activeTab === 'lotes' ? '#084ca4' : '#c4c4c4',
                            }}
                          >
                            Dados Do Lote/Item
                          </button>
                        </div>
                      </a>
                      <a
                        href="#"
                        className="card-download-link"
                        onClick={showDownload}
                      >
                        <div
                          id="card-download"
                          className="one tabs"
                          style={{
                            marginBottom: '1em',
                            backgroundColor:
                              activeTab === 'download' ? '#084ca4' : '#c4c4c4',
                          }}
                        >
                          <div id="download-icon">
                            <DownloadIcon width="25" height="30" />
                          </div>
                          <button
                            className="button-visualizar-documentos"
                            style={{
                              backgroundColor:
                                activeTab === 'download'
                                  ? '#084ca4'
                                  : '#c4c4c4',
                            }}
                          >
                            Documentos
                          </button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <hr style={{ marginTop: '3em' }} />
                {activeTab === 'detalhes' && (
                  <div className="div-detalhes">
                    <div className="div-two">
                      <div className="row">
                        <div id="bold" className="column">
                          Nome do Órgão Promotor:
                        </div>

                        <div id="bold" className="column">
                          CNPJ:
                        </div>
                        <div className="column"></div>
                        <div className="column"></div>
                      </div>
                    </div>
                    <div className="div-three">
                      <div className="row">
                        <div id="blue" className="column">
                          {edital.nomeDoOrgaoPromotor}
                        </div>
                        <div id="blue" className="column">
                          {edital.cnpj}
                        </div>
                        <div className="column"></div>
                        <div className="column"></div>
                      </div>
                    </div>
                    <div className="div-four">
                      <div className="row">
                        <div id="bold" className="column">
                          Modalidade
                        </div>

                        <div id="bold" className="column">
                          Utiliza Verba Federal
                        </div>
                        <div id="bold" className="column">
                          Amparo Legal
                        </div>
                        <div className="column"></div>
                      </div>
                    </div>
                    <div className="div-five">
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">
                          {edital?.modalidade
                            ? getModalidadeLabel(edital.modalidade)
                            : '-'}
                        </div>
                        <div className="column">
                          {edital?.utilizaVerbaFederal ? 'Sim' : 'Não'}
                        </div>

                        <div className="column">{edital.amparoLegal}</div>
                        <div className="column"></div>
                      </div>
                    </div>
                    <div className="div-six">
                      <div className="row">
                        <div id="bold" className="column">
                          Finalidade da Licitação / Operação
                        </div>
                        <div id="bold" className="column">
                          Modelo da Ata
                        </div>
                        <div id="bold" className="column">
                          Número do Edital
                        </div>
                        <div id="bold" className="column">
                          Número do Processo
                        </div>
                      </div>
                    </div>

                    <div className="div-eight">
                      <div className="header" style={{ marginBottom: '16px' }}>
                        <div className="column">
                          {edital.finalidadeDeLicitacao}
                        </div>
                        <div className="column">
                          {edital.modeloDeAta === null
                            ? '(---)'
                            : edital.modeloDeAta}
                        </div>
                        <div className="column">{edital.numeroDoEdital}</div>
                        <div className="column">{edital.numeroDoProcesso}</div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Pregoeiro / Agente de Contratação
                        </div>
                        <div id="bold" className="column">
                          Telefone
                        </div>
                        <div id="bold" className="column">
                          Email
                        </div>
                        <div className="column"></div>
                      </div>
                    </div>

                    <div className="div-nine">
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">
                          {pregoeiro.length > 0 ? pregoeiro[0].nome : '-'}
                        </div>

                        <div className="column">{edital.telefone}</div>

                        <div className="column">{edital.email}</div>
                        <div className="column"></div>
                      </div>
                    </div>
                    <div className="div-ten">
                      <div className="row">
                        <div id="bold" className="column">
                          Unidade Compradora
                        </div>
                        <div id="bold" className="column">
                          Endereço
                        </div>
                        <div id="bold" className="column">
                          Número
                        </div>
                        <div id="bold" className="column">
                          Complemento
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">{edital.unidadeCompradora}</div>
                        <div className="column">{edital.endereco}</div>
                        <div className="column">{edital.numero}</div>
                        <div className="column">{edital.complemento}</div>
                      </div>
                    </div>
                    <div className="div-eleven">
                      <div className="row">
                        <div id="bold" className="column">
                          CEP
                        </div>
                        <div id="bold" className="column">
                          Bairro
                        </div>
                        <div id="bold" className="column">
                          Cidade
                        </div>
                        <div id="bold" className="column">
                          Estado
                        </div>
                      </div>

                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">{edital.cep}</div>
                        <div className="column">{edital.bairro}</div>
                        <div className="column">{edital.cidade}</div>
                        <div className="column">{edital.estado}</div>
                      </div>
                    </div>
                    <hr />

                    <div className="div-twelve">
                      <div className="header">
                        <div className="row">
                          <div id="blue" className="column">
                            Equipe de Apoio
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-thirteen">
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div
                          className="column"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {equipeDeApoio && equipeDeApoio.length > 0 ? (
                            equipeDeApoio.map((membro, index) => (
                              <div
                                key={index}
                                className="column"
                                style={{ marginBottom: '0.5em' }}
                              >
                                {membro.Nome}
                              </div>
                            ))
                          ) : (
                            <div
                              className="column"
                              style={{ marginBottom: '0.5em' }}
                            >
                              -
                            </div>
                          )}
                          <div className="column"></div>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div className="div-fourteen">
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div id="blue" className="column">
                          Objeto e Segmentação da Licitação
                        </div>
                        <div id="blue" className="column">
                          Julgamento e Validade Da Proposta
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Objetos
                        </div>
                        <div id="bold" className="column">
                          Segmentos
                        </div>
                        <div id="bold" className="column">
                          Critério de Julgamento da proposta e lance
                        </div>
                        <div className="column"></div>
                      </div>
                      {segmentacoes.map((segmento, index) => (
                        <div
                          className="row"
                          style={{ marginBottom: '16px' }}
                          key={index}
                        >
                          <div className="column">{segmento.Objeto}</div>
                          <div className="column">{segmento.Segmento}</div>
                          {index === 0 && (
                            <div className="column">
                              {edital.criteriosDeJulgamento}
                            </div>
                          )}
                          <div className="column"></div>
                        </div>
                      ))}

                      <div className="row">
                        <div id="bold" className="column">
                          Validade da Proposta
                        </div>
                        <div className="column"></div>
                      </div>
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">
                          {edital.valorDaProposta} dias
                        </div>
                        <div className="column"></div>
                      </div>
                      <hr />
                      <div className="div-twelve">
                        <div className="header">
                          <div
                            id="blue"
                            className="column"
                            style={{ marginBottom: '0.5em' }}
                          >
                            Data e Hora Referente a
                          </div>
                        </div>
                      </div>

                      {/* First row: Labels */}
                      <div className="div-thirteen">
                        <div className="row">
                          <div id="bold" className="column">
                            Início do recebimento de propostas
                          </div>

                          {/* Conditionally render based on Credenciamento */}
                          {edital.modalidade === '6' && (
                            <>
                              <div id="bold" className="column">
                                Sem prazo
                              </div>

                              <div id="bold" className="column">
                                Término do recebimento
                              </div>
                            </>
                          )}

                          {/* Other modalities */}
                          {edital.modalidade !== '6' && (
                            <>
                              <div id="bold" className="column">
                                Término do recebimento de propostas
                              </div>
                              {(edital.modalidade === '1' ||
                                edital.modalidade === '3') && (
                                  <>
                                    <div id="bold" className="column">
                                      Abertura e Análise
                                    </div>
                                    <div id="bold" className="column">
                                      Início de Lances
                                    </div>
                                  </>
                                )}
                              {edital.modalidade === '5' && (
                                <>
                                  <div id="bold" className="column">
                                    Início de Lances
                                  </div>
                                  <div id="bold" className="column">
                                    Duração de Lances
                                  </div>
                                  <div id="bold" className="column">
                                    Término de Lances
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        {/* Second row: Data */}
                        <div className="row">
                          <div className="column">
                            {formatDateToLocale(
                              edital.inicioDoRecebimentoDePropostas,
                            ) || '-'}
                          </div>

                          {edital.modalidade === '6' && (
                            <>
                              {/* Sem prazo checkmark logic */}
                              <div className="column">
                                <div className="row">
                                  <div className="radio-container">
                                    <span style={{ marginRight: '8px' }}>
                                      Sim
                                    </span>
                                    <input
                                      className="semprazo"
                                      type="radio"
                                      checked={!edital.terminoDeRecebimento}
                                      readOnly
                                    />
                                  </div>
                                  <div className="radio-container">
                                    <span
                                      style={{
                                        marginRight: '8px',
                                        marginLeft: '8px',
                                      }}
                                    >
                                      Não
                                    </span>
                                    <input
                                      className="semprazo"
                                      type="radio"
                                      checked={!!edital.terminoDeRecebimento}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Show Término do recebimento only if it exists */}

                              <div className="column">
                                {formatDateToLocale(
                                  edital.terminoDeRecebimento,
                                ) || '-'}
                              </div>
                            </>
                          )}

                          {edital.modalidade !== '6' && (
                            <>
                              <div className="column">
                                {formatDateToLocale(
                                  edital.terminoDeRecebimento,
                                ) || '-'}
                              </div>

                              {(edital.modalidade === '1' ||
                                edital.modalidade === '3') && (
                                  <>
                                    <div className="column">
                                      {formatDateToLocale(
                                        edital.aberturaEAnalise,
                                      ) || '-'}
                                    </div>
                                    <div className="column">
                                      {formatDateToLocale(
                                        edital.inicioDeLances,
                                      ) || '-'}
                                    </div>
                                  </>
                                )}

                              {edital.modalidade === '5' && (
                                <>
                                  <div className="column">
                                    {formatDateToLocale(
                                      edital.inicioDeLances,
                                    ) || '-'}
                                  </div>
                                  <div className="column">
                                    {formatDateToLocale(
                                      edital.duracaoDeLances,
                                    ) || '-'}
                                  </div>
                                  <div className="column">
                                    {formatDateToLocale(
                                      edital.terminoDeLances,
                                    ) || '-'}
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div id="blue" className="column">
                          Sala de Disputa
                        </div>
                      </div>
                      <div className="row">
                        <div id="bold" className="column">
                          Modo de disputa
                        </div>
                        <div id="bold" className="column">
                          Os lances devem considerar o valor
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: '16px' }}>
                        <div className="column">{edital.modoDeDisputa}</div>
                        <div className="column">{edital.tipoValorLance}</div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'lotes' && (
                  <div className="div-lotes">
                    {edital.lotes && edital.lotes.length > 0 ? (
                      edital.lotes.map((lote, loteIndex) => (
                        <div key={loteIndex}>
                          <a
                            href="#"
                            className="ativar-lote"
                            onClick={() => toggleLote(loteIndex)}
                          >
                            <div className="lote">
                              <span className="title-lote">
                                Lote {loteIndex + 1}
                              </span>
                              <span
                                className={`arrow ${visibleLotes[loteIndex] ? 'up' : ''}`}
                              ></span>
                            </div>
                          </a>

                          {visibleLotes[loteIndex] && (
                            <div
                              className={`lote-${loteIndex}`}
                              style={{ marginLeft: '36px' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>
                                    Número do Lote
                                  </span>
                                  <div
                                    style={{
                                      backgroundColor: '#dcecec',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '6px',
                                      padding: '4px 0px 4px 0',
                                      marginTop: '8px',
                                    }}
                                  >
                                    <span>{loteIndex + 1}</span>
                                  </div>
                                </div>
                                <div></div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>
                                    Valor total do lote
                                  </span>
                                  <span>
                                    R${' '}
                                    {lote.reduce(
                                      (total, produto) =>
                                        total +
                                        produto.preco * produto.quantidade,
                                      0,
                                    )}
                                  </span>
                                </div>
                                <div></div>
                              </div>

                              {/* Exibe os itens do lote */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',

                                  marginTop: '16px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '150px',
                                    maxWidth: '150px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      maxWidth: '40px',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>
                                      Itens
                                    </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {lote.length}
                                    </span>
                                  </div>
                                </div>

                                <div style={{ width: '100%' }}>
                                  {lote.map((produto, produtoIndex) => (
                                    <div
                                      key={produtoIndex}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: '16px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Produto
                                        </span>
                                        <span>{produto.produto}</span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Quantidade
                                        </span>
                                        <span>{produto.quantidade}</span>
                                      </div>

                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Unidade
                                        </span>
                                        <span>{produto.unidade}</span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Preço de referência
                                        </span>
                                        <span>
                                          R$ {produto.preco.toFixed(2)}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',

                                          minWidth: '200px',
                                          maxWidth: '200px',
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          valor total
                                        </span>
                                        <span>
                                          R${' '}
                                          {(
                                            produto.quantidade * produto.preco
                                          ).toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>Não há lotes disponíveis</p>
                    )}
                  </div>
                )}
                {activeTab === 'download' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <span id="blue">Documentos do Edital</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundColor: '#dee8ed',
                        paddingBottom: '0.7em',
                        paddingTop: '0.7em',
                        paddingRight: '20em',
                        paddingLeft: '1em',
                        marginTop: '3em',
                      }}
                    >
                      <span id="bold">Documento</span>
                      <span id="bold">Download</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0.7em',
                        paddingTop: '0.7em',
                        paddingRight: '20em',
                        paddingLeft: '1em',
                        marginTop: '1em',
                      }}
                    >
                      <span id="bold">Edital</span>
                      <div>
                        <button
                          style={{
                            backgroundColor: '#084ca4',
                            borderRadius: '100%',
                            height: '40px',
                            width: '40px',
                          }}
                          onClick={(e) => {
                            e.preventDefault(); console.log('Botão de download clicado');
                            let documentos = edital.documentosDoEdital; console.log('documentosDoEdital inicial:', documentos);
                            if (typeof documentos === 'string') {
                              try {
                                documentos = JSON.parse(documentos);
                                console.log('documentosDoEdital parseado:', documentos);
                              }
                              catch (error) {
                                console.error('Erro ao parsear documentosDoEdital:', error);
                                message.error('Falha ao processar os documentos do edital'); return;
                              }
                            }
                            if (Array.isArray(documentos)) {
                              console.log('documentosDoEdital é um array:', documentos);
                              const editalLink = documentos.find(doc => doc.Tipo === 'documentoDoEdital')?.Link;
                              console.log('Link do documentoDoEdital:', editalLink);
                              if (editalLink) { window.open(editalLink, '_blank'); }
                              else {
                                console.log('Link do documento não encontrado');
                                message.error('Falha ao obter o link do documento');
                              }
                            }
                            else {
                              console.log('documentosDoEdital não é um array');
                              message.error('Falha ao obter o link do documento');
                            }
                          }}
                        >
                          <DownloadIcon width="25" height="30" color="white" />
                        </button>
                      </div>
                    </div>
                    <hr />
                    {edital.documentosDoEdital && (
                      <>
                        <div>
                          <span id="blue">Documentos Complementares</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#dee8ed',
                            paddingBottom: '0.7em',
                            paddingTop: '0.7em',
                            paddingRight: '20em',
                            paddingLeft: '1em',
                            marginTop: '3em',
                          }}
                        >
                          <span id="bold">Documento</span>
                          <span id="bold">Download</span>
                        </div>
                        <div>
                          {Array.isArray(JSON.parse(edital.documentosDoEdital)) &&
                            JSON.parse(edital.documentosDoEdital).length > 0 ? (
                            JSON.parse(edital.documentosDoEdital)
                              .filter(documento => documento.Tipo !== 'Relatorio') // Filtro para excluir relatórios
                              .map((documento, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingBottom: '0.7em',
                                    paddingTop: '0.7em',
                                    paddingRight: '20em',
                                    paddingLeft: '1em',
                                    marginTop: '1em',
                                  }}
                                >
                                  <span id="bold">
                                    {documento.Nome ? documento.Nome : 'sem dados'}
                                  </span>
                                  <div>
                                    {documento.Link ? (
                                      <a href={documento.Link} download>
                                        <button
                                          style={{
                                            backgroundColor: '#084ca4',
                                            borderRadius: '100%',
                                            height: '40px',
                                            width: '40px',
                                          }}
                                        >
                                          <DownloadIcon width="25" height="30" color="white" />
                                        </button>
                                      </a>
                                    ) : (
                                      <button
                                        style={{
                                          backgroundColor: '#ccc',
                                          borderRadius: '100%',
                                          height: '40px',
                                          width: '40px',
                                          cursor: 'not-allowed',
                                        }}
                                        disabled
                                      >
                                        <DownloadIcon width="25" height="30" color="white" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: '0.7em',
                                paddingTop: '0.7em',
                                paddingRight: '20em',
                                paddingLeft: '1em',
                                marginTop: '1em',
                              }}
                            >
                              <span style={{ color: 'red' }} id="bold">
                                Nenhum documento disponível
                              </span>
                              <button
                                style={{
                                  backgroundColor: '#084ca4',
                                  fontSize: '2rem',
                                  color: 'white',
                                  borderRadius: '100%',
                                  height: '40px',
                                  width: '40px',
                                  cursor: 'not-allowed',
                                }}
                                id="bold"
                                disabled
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>

                        <div>
                          <span id="blue">Relatórios</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#dee8ed',
                            paddingBottom: '0.7em',
                            paddingTop: '0.7em',
                            paddingRight: '20em',
                            paddingLeft: '1em',
                            marginTop: '3em',
                          }}
                        >
                          <span id="bold">Documento</span>
                          <span id="bold">Download</span>
                        </div>
                        <div>
                          {(() => {
                            const documentos = JSON.parse(edital.documentosDoEdital);
                            const relatorios = Array.isArray(documentos)
                              ? documentos.filter(doc => doc.Tipo === 'Relatorio')  // Filtrando documentos do tipo 'relatorio'
                              : [];

                            return relatorios.length > 0 ? (
                              relatorios.map((documento, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingBottom: '0.7em',
                                    paddingTop: '0.7em',
                                    paddingRight: '20em',
                                    paddingLeft: '1em',
                                    marginTop: '1em',
                                  }}
                                >
                                  <span id="bold">
                                    {documento.Nome ? documento.Nome : 'sem dados'}
                                  </span>

                                  <div>
                                    {documento.Link ? (
                                      <a href={documento.Link} download>
                                        <button
                                          style={{
                                            backgroundColor: '#084ca4',
                                            borderRadius: '100%',
                                            height: '40px',
                                            width: '40px',
                                          }}
                                        >
                                          <DownloadIcon
                                            width="25"
                                            height="30"
                                            color="white"
                                          />
                                        </button>
                                      </a>
                                    ) : (
                                      <button
                                        style={{
                                          backgroundColor: '#ccc',
                                          borderRadius: '100%',
                                          height: '40px',
                                          width: '40px',
                                          cursor: 'not-allowed',
                                        }}
                                        disabled
                                      >
                                        <DownloadIcon
                                          width="25"
                                          height="30"
                                          color="white"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: '0.7em',
                                  paddingTop: '0.7em',
                                  paddingRight: '20em',
                                  paddingLeft: '1em',
                                  marginTop: '1em',
                                }}
                              >
                                <span style={{ color: 'red' }} id="bold">
                                  Nenhum relatório disponível
                                </span>
                                <button
                                  style={{
                                    backgroundColor: '#084ca4',
                                    fontSize: '2rem',
                                    color: 'white',
                                    borderRadius: '100%',
                                    height: '40px',
                                    width: '40px',
                                    cursor: 'not-allowed',
                                  }}
                                  id="bold"
                                  disabled
                                >
                                  X
                                </button>
                              </div>
                            );
                          })()}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </DetailsContainer>
      <EditalDownloadModal
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
        edital={editalCurrent}
      />
    </ModalCustom>
  );
};
